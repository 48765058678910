import React from "react";
import { 
  Box,
  Flex
 } from '@chakra-ui/react'
import styled from "@emotion/styled";
import Ice from "../../images/shortIce.jpg"
import InfoOval from "./InfoOval";

const IceMask = styled.svg`
  width: 100vw;
  height: 100%;
  .cls-1 {
        fill: none;
      }

      .cls-2 {
        clip-path: url(#clip-path);
      }

      .cls-3 {
        isolation: isolate;
      }

      .cls-4 {
        fill: #cc2027;
      }
  `


const PageIceMask = (props) => {
  console.log(props)
  return (
<Flex w="100%" h="100%" display={{base: 'none', lg: 'block'}}>
  <Box w="100%" h="100%" position="absolute">
    <Flex width="100%" h="100%" justifyContent="flex-end" alignItems="flex-start">
      <InfoOval 
      {...props} />    
  </Flex>
</Box>

  <IceMask xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 3840 1970.07043">
    <defs>
      <clipPath id="clip-path">
        <path class="cls-1" d="M3839.90771,0H0V1411.26038c88.00732-69.5337,228.9776-164.17163,365.993-185.15577,222.31323-34.0476,424.10608-15.40441,759.35864,87.55225,335.25257,102.95654,2404.5785,608.5376,2404.5785,608.5376l136.35913,47.876L3840,1970.07019Z"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g class="cls-2">
          <image class="cls-3" width="3840" height="1965" xlinkHref={Ice} />
        </g>
        <g id="Layer_2-2" data-name="Layer 2">
          <g id="Layer_1-2-2" data-name="Layer 1-2">
            <g id="Layer_2-2-2" data-name="Layer 2-2">
              <g id="Layer_1-2-2-2" data-name="Layer 1-2-2">
                <path class="cls-4" d="M401.64893,1171.10437c220.105-34.04773,419.89349-15.40442,751.81591,87.55225C1485.38721,1361.61316,3534.158,1867.194,3534.158,1867.194l290.1001,102.87622H3840v-20.34741l-331.08643-125.85669s-2016.05529-533.30225-2342.67675-641.891c-326.62152-108.58875-523.20514-128.247-739.81-92.33863C244.9845,1119.71582,56.43652,1293.31531,0,1348.88684v39.89575C79.30176,1320.692,243.14764,1195.62268,401.64893,1171.10437Z"/>
                <path d="M1125.35181,1313.65686c335.25244,102.95654,2404.57837,608.5376,2404.57837,608.5376l136.35913,47.876h157.96875L3534.158,1867.194s-2048.77075-505.58081-2380.69324-608.5376c-331.92236-102.95666-531.71087-121.59985-751.81586-87.55224C243.14764,1195.62268,79.30176,1320.692,0,1388.78259v22.47779c88.00732-69.5337,228.9776-164.17163,365.993-185.15577C588.30627,1192.057,790.09918,1210.70032,1125.35181,1313.65686Z"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </IceMask>
</Flex>
  );
};

export default PageIceMask;
