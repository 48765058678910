import * as React from "react";
import { graphql } from "gatsby";
import { Box, Container, Heading } from "@chakra-ui/react";
import { getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import PageIceMask from "../components/IceMasks/PageIceMask";
import SecondaryHero from "../components/SecondaryHero/SecondaryHero";
import HomeLowerCurve from "../components/HomePage/HomeLowerCurve";
import ProgramsCards from "../components/ProgramsCards/ProgramsCards";

import StripeDivder from "../components/IceMasks/StripeDivider";
import BackgroundImage from '../components/Backgrounds/BackgroundImage'
import DonateNewsletterBlock from "../components/DonateNewsletterBlock/DonateNewsletterBlock";
import Footer from "../components/Footer/Footer"
import EventsCarousel from "../components/Events/EventsCarousel";
import ProgramSlider from "../components/ProgramsCards/ProgramSlider";

const ProgramsPage = ({ data }) => {
  if (!data) return null;
  const document = data.allPrismicPage.edges[0].node.data;
  const secondaryImage = document.secondary_hero_image.localFile;
  const backgroundImage = data.allFile.edges[0].node.childImageSharp;

  return (
    <>
    <Seo title="Snow King Sports &amp; Events Center | Programs" />
        {/* <PageIceMask 
          title="Fun For All Ages"
          description="Don't miss all the excitement happening at the Snow King Ice Rink and Events Center. Our 501(c)(3) is driving Youth and Adult Ice Sports Year Round."
          buttonOneLinkTo="/programs"
          buttonOneText="Book Your Event"
          buttonTwoLinkTo="/facility"
          buttonTwoText="Facility Details"
          mr="10%"
          mt="12%"
        /> */}
        <BackgroundImage image={backgroundImage}>
            <Container maxW="container.lg" pt="48" pb="32">
            <Box pt="24" px="2" textAlign="center" display={{base: 'block', lg: 'block'}}>
            <Heading as="h1" variant="page-title" lineHeight="1" fontSize="clamp(2.25rem, 5vw, 4rem)">
              Get On The Ice
              </Heading>
              <Heading as="h3" color="brand.600" fontSize="clamp(1.25rem, 5vw, 2rem)">
              Sign Up for Snow King SEC Programs
              </Heading>
          </Box>
            </Container>
      
        </BackgroundImage>
      {/* <SecondaryHero
        secondaryImg={secondaryImage}
        alt={document.secondary_hero_image.alt}
      /> */}
      
      {/* <Box
        style={{
          width: "100%",
          marginTop: "15%",
          marginBottom: '-1px'
        }}
        display={{base: 'none', lg: 'block'}}
      >
        <HomeLowerCurve
          pageTitle="Get On The Ice"
          subTitle="sign up for available programs"
        />
      </Box> */}


      <Box backgroundColor="white"  pt="8">
        <Container maxW="container.xl">
        <ProgramsCards />
        </Container>
        
        <StripeDivder />
        <Heading as="h1" variant="page-title" mb="4" mt="20">Upcoming Events</Heading>
        <Box  py="8"><EventsCarousel /></Box>
        <BackgroundImage
                    backgroundImage={getImage(backgroundImage)}
                >
        <StripeDivder />
        
        
        <DonateNewsletterBlock
          title="Your Support Makes All The Difference"
          content="Now, of course not, Biff, now, I wouldn't want that to happen. Well, now we gotta sneak this back into my laboratory, we've gotta get you home. Yeah. I can't play. Just say anything, George, say what ever's natural, the first thing that comes to your mind."
          btnText="Donate Now"
          btnLink="/donate"
        />
        <Footer />
      </BackgroundImage>
      </Box>
    </>
  );
};

export const query = graphql`
  {
    allFile(filter: {relativePath: {eq: "Ice.jpg"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 80)
          }
        }
      }
    }
    allPrismicPage(filter: { uid: { eq: "programs" } }) {
      edges {
        node {
          uid
          data {
            hero_image {
              localFile {
                absolutePath
                publicURL
              }
              alt
            }
            secondary_hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            page_title {
              text
            }
            page_subtitle {
              text
            }
          }
        }
      }
    }
  }
`;

export default ProgramsPage;
